import React, { useState, useEffect, useCallback, useRef } from "react";
import { ThemeProvider } from "@mui/material/styles";
import { GetAlertwestTheme } from "@digitalpath-inc/alertwest-components-react/theme";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import MenuDrawer from "./menu/menu.component";
import FilterMenuDrawer from "./filterMenu/filterMenu.component";
import Navbar from "./navbar/navbar.component";

import { getAuthData, subscribeToAuthData, initializeAuthData } from "@alertwest/auth";
import { navigateToUrl } from "single-spa";

// Login Overlay Component
const LoginOverlay = ({ visible, onLogin }) => {
  // Use a ref to track if component is mounted
  const isMounted = useRef(true);
  
  // Effect for cleanup
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);
  
  if (!visible) return null;
  
  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 9999,
      }}
    >
      <Box
        sx={{
          backgroundColor: 'rgba(24, 24, 27, 0.9)',
          padding: 4,
          borderRadius: 2,
          maxWidth: '400px',
          textAlign: 'center',
        }}
      >
        <Typography variant="h5" color="primary" gutterBottom>
          Session Expired
        </Typography>
        <Typography variant="body1" color="white" paragraph>
          Your session has expired or you are not logged in. Please log in to continue.
        </Typography>
        <Button 
          variant="contained" 
          color="primary" 
          onClick={onLogin}
        >
          Log In
        </Button>
      </Box>
    </Box>
  );
};

export default function Root(props) {
    const [menuOpen, setMenuOpen] = useState(false);
    const [filterMenuOpen, setFilterMenuOpen] = useState(false);
    const [authState, setAuthState] = useState(getAuthData());
    const [currentPath, setCurrentPath] = useState(window.location.pathname);
    const pathCheckIntervalRef = useRef(null);
    const isNavigatingRef = useRef(false);

    useEffect(() => {
      const unsubscribe = subscribeToAuthData((newAuthState) => {
          setAuthState(newAuthState);
          console.log('New Auth State: ', newAuthState);
      });

      if (!authState?.isInitialized) {
        initializeAuthData();
      }
      
      // Listen for URL changes
      const handleLocationChange = () => {
        // Set a flag to indicate we're in the middle of navigation
        isNavigatingRef.current = true;
        
        // Use requestAnimationFrame to delay path update until after the current render cycle
        requestAnimationFrame(() => {
          setCurrentPath(window.location.pathname);
          
          // Clear the navigation flag after a short delay to allow components to stabilize
          setTimeout(() => {
            isNavigatingRef.current = false;
          }, 100);
        });
      };

      window.addEventListener('popstate', handleLocationChange);
      
      // Use a more efficient approach for checking path changes
      if (pathCheckIntervalRef.current) {
        clearInterval(pathCheckIntervalRef.current);
      }
      
      pathCheckIntervalRef.current = setInterval(() => {
        if (currentPath !== window.location.pathname && !isNavigatingRef.current) {
          handleLocationChange();
        }
      }, 300);
      
      // Handle any ResizeObserver errors
      const handleResizeObserverError = (e) => {
        if (e.message && e.message.includes('ResizeObserver')) {
          // Prevent the error from being reported
          e.stopImmediatePropagation();
        }
      };
      
      window.addEventListener('error', handleResizeObserverError, true);
      
      return () => {
          unsubscribe();
          window.removeEventListener('popstate', handleLocationChange);
          window.removeEventListener('error', handleResizeObserverError, true);
          
          if (pathCheckIntervalRef.current) {
            clearInterval(pathCheckIntervalRef.current);
            pathCheckIntervalRef.current = null;
          }
      };
    }, []);

    const toggleMenu = () => {
        setFilterMenuOpen(false);
        setMenuOpen(current => !current);
    }

    const toggleFilterMenu = () => {
        setMenuOpen(false);
        setFilterMenuOpen(current => !current);
    }

    const handleLogin = () => {
        // Set the navigation flag before navigating
        isNavigatingRef.current = true;
        
        // Use a small timeout to ensure state updates complete before navigation
        setTimeout(() => {
          navigateToUrl('/login');
        }, 10);
    }

    const theme = GetAlertwestTheme({});

    // Check if user is not logged in (session invalid) and not already on login page
    const isOnLoginPage = currentPath.startsWith('/login');
    const isSessionInvalid = authState?.isInitialized && !authState?.userAttributes && !isOnLoginPage;

    return (
        <ThemeProvider theme={theme}>
            <Navbar 
                handleMenuOpen={toggleMenu}
                // handleFilterMenuOpen={toggleFilterMenu}
                userAttributes={authState?.userAttributes}
            />
            <MenuDrawer 
                open={menuOpen}
                handleDrawerOpen={toggleMenu}
            />
            {/* <FilterMenuDrawer 
                open={filterMenuOpen}
                handleDrawerOpen={toggleFilterMenu}
            /> */}
            <LoginOverlay 
                visible={isSessionInvalid} 
                onLogin={handleLogin} 
            />
        </ThemeProvider>
    );
}