import React, { useState, useRef, useEffect } from 'react';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AlarmAddIcon from '@mui/icons-material/AlarmAdd';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import HomeIcon from '@mui/icons-material/Home';
import SettingsIcon from '@mui/icons-material/Settings';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import { styled } from '@mui/material/styles';
import { navigateToUrl } from 'single-spa';

const DRAWER_WIDTH = 250;  // Define the width of the main menu drawer

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  '.MuiDrawer-paper': {
    marginTop: '64px',
    height: `calc(100% - 64px)`,
    width: DRAWER_WIDTH,
    backgroundColor: '#1A1816',
    backgroundImage: 'none',
    color: '#FFFFFF',
  }
}));

const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  '&:hover': {
    backgroundColor: '#2D2B28',
  },
}));

const SubMenu = styled(Box)(({ theme }) => ({
  position: 'fixed',
  backgroundColor: '#1A1816',
  color: '#FFFFFF',
  minWidth: 200,
  boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
  zIndex: theme.zIndex.drawer + 1,
}));

const MenuContext = React.createContext();

const MenuItem = ({ item, toggleMenu, depth = 0, path = [] }) => {
  const ref = useRef(null);
  const { openMenus, setOpenMenus } = React.useContext(MenuContext);
  const currentPath = [...path, item.text];
  const isOpen = currentPath.every((item, index) => openMenus[index] === item);

  const [subMenuPosition, setSubMenuPosition] = useState({ top: 0, left: 0 });

  useEffect(() => {
    const updatePosition = () => {
      if (ref.current) {
        const rect = ref.current.getBoundingClientRect();
        setSubMenuPosition({
          top: rect.top,
          left: depth === 0 ? DRAWER_WIDTH : rect.right, // Use DRAWER_WIDTH for top-level items
        });
      }
    };

    updatePosition();
    window.addEventListener('resize', updatePosition);
    return () => window.removeEventListener('resize', updatePosition);
  }, [depth]);

  const handleMouseEnter = () => {
    setOpenMenus(prev => {
      const newOpenMenus = [...prev];
      newOpenMenus[depth] = item.text;
      return newOpenMenus.slice(0, depth + 1);
    });
  };

  const handleMouseLeave = (e) => {
    setOpenMenus(prev => prev.slice(0, depth));
  };

  const handleClick = () => {
    item.onClick();
    toggleMenu();
    }

  return (
    <div ref={ref} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <ListItem disablePadding>
        <StyledListItemButton onClick={handleClick}>
          {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
          <ListItemText primary={item.text} />
          {item.children && <ChevronRightIcon />}
        </StyledListItemButton>
      </ListItem>
      {item.children && isOpen && (
        <SubMenu
          className="submenu"
          style={{
            top: `${subMenuPosition.top}px`,
            left: `${subMenuPosition.left}px`,
          }}
        >
          <List>
            {item.children.map((child, index) => (
              <MenuItem key={index} item={child} depth={depth + 1} path={currentPath} toggleMenu={toggleMenu}/>
            ))}
          </List>
        </SubMenu>
      )}
    </div>
  );
};

const menuItems = [
  {
    text: 'Home',
    icon: <HomeIcon sx={{ color: '#FFFFFF' }} />,
    onClick: () => navigateToUrl('/'),
  },
  // {
  //   text: 'AI Utils',
  //   icon: <AlarmAddIcon sx={{ color: '#FFFFFF' }} />,
  //   onClick: () => console.log('AI Utils clicked'),
  //   children: [
  //     {
  //       text: 'AI Metrics',
  //       onClick: () => navigateToUrl('/ai-utils/metrics'),
  //     },
  //     {
  //       text: 'MLFlow',
  //       onClick: () => navigateToUrl('/ai-utils/mlflow'),
  //     }
  //   ]
  // },
  {
    text: 'Propagation',
    icon: <LocalFireDepartmentIcon sx={{ color: '#FFFFFF' }} />,
    onClick: () => navigateToUrl('/propagation'),
  }
];

const DrawerContent = ({ toggleMenu }) => {
  const [openMenus, setOpenMenus] = useState([]);

  return (
    <MenuContext.Provider value={{ openMenus, setOpenMenus }}>
      <Box sx={{ width: DRAWER_WIDTH }} role="presentation">
        <Box sx={{ height: 10 }} />
        <List>
          {menuItems.map((item, index) => (
            <MenuItem key={index} item={item} toggleMenu={toggleMenu}/>
          ))}
        </List>
        <Divider sx={{ backgroundColor: '#423D38' }} />
      </Box>
    </MenuContext.Provider>
  );
};

export default function MenuDrawer({ open, handleDrawerOpen }) {
  return (
    <StyledDrawer open={open} onClose={() => handleDrawerOpen()}>
      <DrawerContent toggleMenu={handleDrawerOpen}/>
    </StyledDrawer>
  );
}